import ApiService from '@/util/api.service'

const defaulState = {
  lprHitAlerts: [],
  lprHitsRemoved: [],
  sensorAlerts: [],
  busAlerts: [],
  deviceAlerts: [],
  incidentAlerts: [],
  hitAlerts: [],
  rapidsosAlerts: [],
  repAlerts: [],
  sosCallAlerts: [],
  externalSensorAlerts: [],
};

const getters = {
  getLprHitAlerts: (state) => state.lprHitAlerts,
  getSensorAlerts: (state) => state.sensorAlerts,
  getExternalSensorAlerts: (state) => state.externalSensorAlerts,
  getBusAlerts: (state) => state.busAlerts,
  getDeviceAlerts: (state) => state.deviceAlerts,
  getIncidentAlerts: (state) => state.incidentAlerts,
  getHitAlerts: (state) => state.hitAlerts,
  getRapidsosAlerts: (state) => state.rapidsosAlerts,
  getReportantAlerts: (state) => state.repAlerts,
  getSOSCallAlerts: (state) => state.sosCallAlerts,
};

const actions = {
  addSOSCallAlerts({ commit }, item) {
    commit('ADD_SOS_CALL_ALERTS', item)
  },
  cleanSosCallAlerts({ commit }) {
    commit('CLEAN_SOS_CALL_ALERTS')
  },
  removeSOSCallAlerts({ commit }, id) {
    commit('REMOVE_SOS_CALL_ALERTS', id)
  },
  addReportantAlerts({ commit }, item) {
    commit('ADD_REPORTANT_ALERTS', item)
  },
  removeReportantAlerts({ commit }, id) {
    commit('REMOVE_REPORTANT_ALERTS', id)
  },
  cleanReportantAlerts({ commit }) {
    commit('CLEAN_REPORTANT_ALERTS')
  },
  addLprHitAlerts({ commit }, item) {
    commit('ADD_LPR_HIT_ALERTS', item)
  },
  removeLprHitAlerts({ commit }, id) {
    commit('REMOVE_LPR_HIT_ALERTS', id)
  },
  addSensorAlerts({ commit }, item) {
    commit('ADD_SENSOR_ALERTS', item)
  },
  addExternalSensorAlerts({ commit }, item) {
    commit('ADD_EXTERNAL_SENSOR_ALERTS', item)
  },
  removeSensorAlerts({ commit }, id) {
    commit('REMOVE_SENSOR_ALERTS', id)
  },
  removeExternalSensorAlerts({ commit }, id) {
    commit('REMOVE_EXTERNAL_SENSOR_ALERTS', id)
  },
  addIncidentAlerts({ commit }, item) {
    commit('ADD_INCIDENT_ALERTS', item)
  },
  removeIncidentAlerts({ commit }, id) {
    commit('REMOVE_INCIDENT_ALERTS', id)
  },
  discardSensorAlerts({ commit }, id) {
    commit('DISCARD_SENSOR_ALERTS', id)
  },
  discardExternalSensorAlerts({ commit }, id) {
    commit('DISCARD_EXTERNAL_SENSOR_ALERTS', id)
  },
  closeExternalSensorAlerts({ commit }, id) {
    commit('CLOSE_EXTERNAL_SENSOR_ALERTS', id)
  },
  createSensorAlerts({ commit }, id) {
    commit('CREATE_SENSOR_ALERT', id)
  },
  closeSensorAlerts({ commit }, id) {
    commit('CLOSE_SENSOR_ALERTS', id)
  },
  addBusAlerts({ commit }, item) {
    commit('ADD_BUS_ALERTS', item)
  },
  addDeviceAlerts({ commit }, item) {
    commit('ADD_DEVICE_ALERTS', item)
  },
  removeDeviceAlerts({ commit }, id) {
    commit('REMOVE_DEVICE_ALERTS', id)
  },
  removeBusAlerts({ commit }, id) {
    commit('REMOVE_BUS_ALERTS', id)
  },
  discardBusAlerts({ commit }, id) {
    commit('DISCARD_BUS_ALERTS', id)
  },
  closeBusAlerts({ commit }, id) {
    commit('CLOSE_BUS_ALERTS', id)
  },
  cleanAlerts({ commit }) {
    commit('CLEAN_ALERTS')
  },
  loadHits({ commit }, hits) {
    commit('LOAD_HITS', hits)
  },
  addHit({ commit }, item) {
    commit('ADD_HIT', item)
  },
  loadRapidsosIncidents({ commit }, incidents) {
    commit('LOAD_RAPIDSOS', incidents)
  },
  addRapidsosIncident({ commit }, incident) {
    commit('ADD_RAPIDSOS', incident)
  },
  removeRapidsosIncident({ commit }, incident) {
    commit('REMOVE_RAPIDSOS', incident)
  },
  discardRapidsosAlerts({ commit }, id) {
    commit('DISCARD_RAPIDSOS_ALERTS', id)
  }
};

const mutations = {
  ADD_SOS_CALL_ALERTS(state, item) {
    item.typeOfItem = 'sosCall'
    item._addedAt = new Date()
    const index = state.sosCallAlerts.findIndex((sosCall) => sosCall.id === item.id)
    if (index === -1) {
      state.sosCallAlerts.push(item)
    } else {
      state.sosCallAlerts[index] = item
    }
  },
  REMOVE_SOS_CALL_ALERTS(state, id) {
    console.log('REMOVE_SOS_CALL_ALERTS', id)
    const index = state.sosCallAlerts.findIndex((sosCall) => sosCall.id === id)
    if (index >= 0) {
      state.sosCallAlerts.splice(index, 1)
    }
  },
  CLEAN_SOS_CALL_ALERTS(state) {
    state.sosCallAlerts = []
  },
  ADD_REPORTANT_ALERTS(state, item) {
    item._addedAt = new Date()
    const index = state.repAlerts.findIndex((reportant) => reportant.additionalData.phone === item.additionalData.phone)
    if (index === -1) {
      console.log('ADD_REPORTANT_ALERTS', item)
      state.repAlerts.push(item)
    } else {
      console.log('UPDATE_REPORTANT_ALERTS', item)
      state.repAlerts[index] = item
    }
  },
  REMOVE_REPORTANT_ALERTS(state, id) {
    const index = state.repAlerts.findIndex((reportant) => reportant.id === id)
    if (index >= 0) {
      state.repAlerts.splice(index, 1)
    }
  },

  CLEAN_REPORTANT_ALERTS(state) {
    const now = (new Date()).getTime()
    const reportans = state.repAlerts.filter((reportant) => {
      if (reportant?.positions?.length > 0) {
        const diff = now - parseInt(reportant.positions[0].additionalData.call_start_time)
        return diff < 20 * 60 * 1000
      }
      return false
    })
    state.repAlerts = reportans
  },
  ADD_LPR_HIT_ALERTS(state, item) {
    item.typeOfItem = 'sensors'
    item._addedAt = new Date(item.lastHit)
    const hours = Math.abs(new Date(item.lastHit) - new Date()) / 36e5
    const removedIndex = state.lprHitsRemoved.findIndex((lpr) => lpr.id === item.id)
    const removed = removedIndex >= 0 && state.lprHitsRemoved[removedIndex].lastHit === item.lastHit
    if (!removed && hours <= 1231231231324 && !state.lprHitAlerts.map((lpr) => lpr.id).includes(item.id)) {
      state.lprHitAlerts.push(item)
    }
  },

  REMOVE_LPR_HIT_ALERTS(state, id) {
    const index = state.lprHitAlerts.findIndex((lpr) => lpr.id === id)
    if (index >= 0) {
      state.lprHitsRemoved.push({
        id: state.lprHitAlerts[index].id,
        lastHit: state.lprHitAlerts[index].lastHit
      })
      state.lprHitAlerts.splice(index, 1)
    }
  },
  ADD_SENSOR_ALERTS(state, item) {
    item.typeOfItem = 'sensors'
    item._addedAt = new Date(item.lastUpdate)
    const index = state.sensorAlerts.findIndex((sensor) => sensor.id === item.id)
    if (index === -1) {
      state.sensorAlerts.push(item)
    }
  },
  ADD_EXTERNAL_SENSOR_ALERTS(state, item) {
    item._addedAt = new Date(item.createdAt) || new Date()
    item.name = item.uniqueId
    const index = state.externalSensorAlerts.findIndex((sensor) => sensor.id === item.id)
    if (index === -1) {
      state.externalSensorAlerts.push(item)
    }
  },
  REMOVE_SENSOR_ALERTS(state, id) {
    const index = state.sensorAlerts.findIndex((sensor) => sensor.uniqueId === id)
    if (index > -1) {
      state.sensorAlerts.splice(index, 1)
    }
  },
  REMOVE_EXTERNAL_SENSOR_ALERTS(state, id) {
    const index = state.externalSensorAlerts.findIndex((sensor) => sensor.uniqueId === id)
    if (index > -1) {
      state.externalSensorAlerts.splice(index, 1)
    }
  },
  ADD_INCIDENT_ALERTS(state, item) {
    item._addedAt = new Date()
    const index = state.incidentAlerts.findIndex((incident) => incident.id === item.id)
    if (index === -1) {
      state.incidentAlerts.unshift(item)
    }
  },
  REMOVE_INCIDENT_ALERTS(state, id) {
    const index = state.incidentAlerts.findIndex((incident) => incident.id === id)
    if (index > -1) {
      state.incidentAlerts.splice(index, 1)
    }
  },
  DISCARD_RAPIDSOS_ALERTS(state, id) {
    ApiService({
      url: 'rapidsos/' + id + '/discardalert',
      method: 'get'
    }).then(() => {
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al descartar la alerta' })
    })
  },
  DISCARD_SENSOR_ALERTS(state, id) {
    ApiService({
      url: 'sensor/' + id + '/discardalert',
      method: 'get'
    }).then(() => {
      this.dispatch('alerts/removeSensorAlerts', id)
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al descartar la alerta' })
    })
  },
  DISCARD_EXTERNAL_SENSOR_ALERTS(state, _id) {
    ApiService({
      url: `trip/discard/${_id}`,
      method: 'put'
    }).then(() => {
      // this.dispatch('alerts/removeSensorAlerts', id)
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al descartar la alerta' })
    })
  },
  CLOSE_EXTERNAL_SENSOR_ALERTS(state, _id) {
    ApiService({
      url: `trip/close/${_id}`,
      method: 'put'
    }).then(() => {
      // this.dispatch('alerts/removeSensorAlerts', id)
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al cerrar la alerta' })
    })
  },
  CREATE_SENSOR_ALERT(state, id) {
    ApiService({
      url: 'sensor/' + id + '/addalert',
      method: 'post',
      data: { alertType: 1, sourceDevice: 'KALAN' }
    }).then(() => {
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al crear la alerta' })
    })
  },
  CLOSE_SENSOR_ALERTS(state, id) {
    ApiService({
      url: 'sensor/' + id + '/closealert',
      method: 'get'
    }).then(() => {
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al cerrar la alerta' })
    })
  },
  ADD_BUS_ALERTS(state, item) {
    item.typeOfItem = 'buses'
    item._addedAt = new Date(item.createdAt)
    const index = state.busAlerts.findIndex((bus) => bus.id === item.id)
    if (index === -1) {
      state.busAlerts.push(item)
    }
  },
  ADD_DEVICE_ALERTS(state, item) {
    item.typeOfItem = 'devices'
    const index = state.deviceAlerts.findIndex((device) => device.id === item.id)
    if (index === -1) {
      state.deviceAlerts.push(item)
    } else {
      state.deviceAlerts[index] = item
    }
  },
  REMOVE_DEVICE_ALERTS(state, id) {
    const index = state.deviceAlerts.findIndex((event) => event.id === id)
    if (index > -1) {
      state.deviceAlerts.splice(index, 1)
    }
  },
  REMOVE_BUS_ALERTS(state, id) {
    const index = state.busAlerts.findIndex((bus) => bus.uniqueId === id)
    if (index > -1) {
      state.busAlerts.splice(index, 1)
    }
  },
  CLOSE_BUS_ALERTS(state, id) {
    ApiService({
      url: 'bus/' + id + '/closealert',
      method: 'get'
    }).then(() => {
      this.dispatch('alerts/removeBusAlerts', id)
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al cerrar la alerta' })
    })
  },
  DISCARD_BUS_ALERTS(state, id) {
    ApiService({
      url: 'bus/' + id + '/discardalert',
      method: 'get'
    }).then(() => {
      this.dispatch('alerts/removeBusAlerts', id)
      state.selectedItem = undefined
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Ocurrió un error al descartar la alerta' })
    })
  },
  CLEAN_ALERTS(state) {
    state.incidentAlerts = []
  },
  LOAD_HITS(state, hits) {
    const mapTypes = { Mandamiento: 'hitMandamiento', LPR: 'hitLpr', VehiculoRobado: 'hitRoboVehiculo' }
    state.hitAlerts = hits.map((hit) => ({
      ...hit,
      typeOfItem: mapTypes[hit.hitType]
    })).reverse()
  },
  ADD_HIT(state, item) {
    state.hitAlerts.unshift(item)
  },
  LOAD_RAPIDSOS(state, incidents) {
    state.rapidsosAlerts = incidents.map((hit) => ({
      ...hit,
      typeof: 'rapidsosIncident',
      typeOfItem: 'rapidsosIncident',
    })).reverse()
  },
  ADD_RAPIDSOS(state, item) {
    item.typeOfItem = 'rapidsosIncident'
    item.typeof = 'rapidsosIncident'
    const index = state.rapidsosAlerts.findIndex((alert) => alert.id === item.id)
    if (index === -1) {
      state.rapidsosAlerts.push(item)
    } else {
      state.rapidsosAlerts[index].status = item.status
    }
  },
  REMOVE_RAPIDSOS(state, id) {
    const index = state.rapidsosAlerts.findIndex((item) => item.id === id)
    if (index > -1) {
      state.rapidsosAlerts.splice(index, 1)
    }
  },
};

export default {
  namespaced: true,
  state() {
    return defaulState
  },
  getters,
  actions,
  mutations
};
