<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"> Inactividad detectada </v-card-title>
      <v-card-text>
        Has estado inactivo por 60 minutos.
        <br /><br />
        <strong>Se cerrará sesión en {{ countdown }} segundos.</strong>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    const countDownInit = 30;
    return {
      inactivityTimeout: null,
      logoutTimeout: null,
      countDownInit,
      countdown: countDownInit,
      dialog: false,
      inactivityTimeLimit: 60 * 60 * 1000,
      countdownInterval: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['getAccessToken'])
  },
  methods: {
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      clearTimeout(this.logoutTimeout);
      clearInterval(this.countdownInterval);
      this.dialog = false;
      this.countdown = this.countDownInit;

      this.inactivityTimeout = setTimeout(() => {
        if (this.getAccessToken) {
          this.showLogoutDialog();
        }
      }, this.inactivityTimeLimit);
    },
    showLogoutDialog() {
      this.dialog = true;
      this.countdown = this.countDownInit;

      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.logout();
        }
      }, 1000);
    },
    stayLoggedIn() {
      clearInterval(this.countdownInterval);
      this.dialog = false;
      this.resetInactivityTimer();
    },
    logout() {
      clearInterval(this.countdownInterval);
      this.dialog = false;
      this.$store.dispatch('auth/logout');
      alert('Sesión cerrada por inactividad');
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
    this.resetInactivityTimer();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
    clearTimeout(this.inactivityTimeout);
    clearTimeout(this.logoutTimeout);
    clearInterval(this.countdownInterval);
  },
};
</script>
