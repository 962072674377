<template>
  <v-app :dark="true">
    <AutoLogout />
    <loading
      :active.sync="overlay"
      :is-full-page="true"
      :can-cancel="false"
      loader="bars"
      color="#264379"
      :opacity="0.35"
    ></loading>
    <v-overlay :value="overlay" :absolute="true"></v-overlay>
    <router-view></router-view>
    <v-navigation-drawer
      class="setting-drawer"
      temporary
      right
      v-model="rightDrawer"
      hide-overlay
      fixed
    >

    </v-navigation-drawer>
    <!-- global snackbar -->
    <v-snackbar
      :timeout="3000"
      app
      top
      centered
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <base-snackbar ref="messages"></base-snackbar>
  </v-app>
</template>

<script>
import NProgress from 'nprogress';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import AutoLogout from "@/components/AutoLogout.vue";

export default {
  components: {
    Loading,
    AutoLogout,
  },
  data() {
    return {
      rightDrawer: false,
      overlay: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  methods: {
    loading(flag = true) {
      this.overlay = flag || false
      if (flag) {
        NProgress.start()
      } else {
        NProgress.done()
      }
    },
    showError(options) {
      const defaults = {
        top: options.bottom ? '' : true,
        right: options.left ? '' : true,
        color: 'error',
        ...options
      }
      this.$refs.messages.addMessage({ ...defaults, message: options })
    },
    showMessage(options) {
      const defaults = {
        top: options.bottom ? '' : true,
        center: true,
        color: 'secondary',
        ...options
      }
      this.$refs.messages.addMessage({ ...defaults, message: options })
    }
  },
  mounted() {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined && window.VMA === undefined) {
      window.VMA = this;
    }
  },
  created() {
    this.$on('SHOW_SNACKBAR', (e) => {
      this.snackbar = {
        show: true,
        text: e.text,
        color: e.color,
      };
    });
    this.$on('AUTH_FAIELD', () => {
      this.snackbar = {
        show: true,
        text: 'Auth Failed',
        color: 'error',
      };
    });
    this.$on('SERVER_ERROR', () => {
      this.snackbar = {
        show: true,
        text: 'Server Error',
        color: 'error',
      };
    });
  }
};
</script>

<style lang="css">
.v-application {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
</style>
